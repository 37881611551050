import * as React from 'react';
import { useSelector } from 'react-redux';

import { isNewbuildingSelector } from 'shared/selectors';
import { selectIsNewbuildingPushToCallEnabled } from 'shared/selectors/abExperiments';
import { getOffer } from 'shared/selectors/newObject';
import { isLeadFactory } from 'shared/utils/agent';
import { pushToCallTooltipShowHandler } from 'shared/utils/newbuildingPushToCall/pushToCallCookie';

interface IUsePushToCallResult {
  isTooltipVisible: boolean;
  onTooltipClose(): void;
}

export const usePushToCall = (): IUsePushToCallResult => {
  const isPushToCallEnabled = useSelector(selectIsNewbuildingPushToCallEnabled);
  const isNewbuilding = useSelector(isNewbuildingSelector);
  const offer = useSelector(getOffer);

  const [isTooltipVisible, setIsTooltipVisible] = React.useState<boolean>(false);

  const isTooltipAvailable = React.useMemo(() => {
    const isFromDeveloper = Boolean(offer.newbuilding?.isFromDeveloper);
    const isAvailable = isFromDeveloper && !isLeadFactory(offer);

    return isPushToCallEnabled && isNewbuilding && isAvailable;
  }, [offer, isNewbuilding, isPushToCallEnabled]);

  const onTooltipClose = React.useCallback(() => setIsTooltipVisible(false), []);

  React.useLayoutEffect(() => {
    if (isTooltipAvailable) {
      setIsTooltipVisible(pushToCallTooltipShowHandler());
    }
  }, [isTooltipAvailable]);

  return React.useMemo(() => ({ isTooltipVisible, onTooltipClose }), [isTooltipVisible, onTooltipClose]);
};
