import { UIHeading2 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingDynamicPhone } from 'shared/containers/NewbuildingDynamicPhone';
import { selectIsNewbuildingPushToCallEnabled } from 'shared/selectors/abExperiments';
import { selectorShowWarningMessage } from 'shared/selectors/contacts/selectorShowWarningMessage';
import { getDynamicCalltrackingSiteBlockId } from 'shared/selectors/getDynamicCalltrackingSiteBlockId';

import { TextWithLinkContainer } from '../../FeedbackExperiment/components/PopupWithForm/TextWithLinkRedesigned';
import { Phones } from '../../components/Phones';
import { PhonesOpenedInfo } from '../../components/PhonesOpenedInfo/PhonesOpenedInfo';
import {
  getAgentAvailability,
  getPhones,
  isNewbuildingFromDeveloperSelector,
  selectIsEnabledCallTracking,
} from '../../selectors';
import { selectWorkTimeInfo } from '../../selectors/newObject';
import { getWorkTime } from '../../utils/getWorkTime';
import { formatNumber, getPhoneLink } from '../../utils/phone';

export const PhonesOpened = () => {
  const isEnabledCallTracking = useSelector(selectIsEnabledCallTracking);
  const isEnabledDynamicCallTracking = !!useSelector(getDynamicCalltrackingSiteBlockId);
  const workTimeInfo = useSelector(selectWorkTimeInfo);
  const agentAvailability = useSelector(getAgentAvailability);
  const showWarningMessage = useSelector(selectorShowWarningMessage);
  const isPushToCallEnabled = useSelector(selectIsNewbuildingPushToCallEnabled);
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);
  const phones = useSelector(getPhones);

  const formattedPhones = React.useMemo(
    () =>
      phones.map(phone => ({
        number: formatNumber(phone),
        link: getPhoneLink(phone),
      })),
    [phones],
  );

  if (!phones.length || phones.some(phone => !phone.countryCode || !phone.number)) {
    return null;
  }

  return (
    <PhonesOpenedInfo
      phones={
        isEnabledDynamicCallTracking ? (
          <UIHeading2>
            <NewbuildingDynamicPhone placeType="ContactsAside" />
          </UIHeading2>
        ) : (
          <Phones size="s" phones={formattedPhones} withQrCode={isNewbuildingFromDeveloper && isPushToCallEnabled} />
        )
      }
      availability={agentAvailability?.title || null}
      schedule={getWorkTime(workTimeInfo, 'Офис работает')}
      warningMessage={showWarningMessage ? 'Ничего не платите до просмотра и подписания договора' : null}
      calltracking={isEnabledCallTracking ? 'Номер только для звонков, сообщения не дойдут' : null}
      feedback={<TextWithLinkContainer />}
    />
  );
};
