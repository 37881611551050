import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingOnlineStatus } from 'shared/components/NewbuildingOnlineStatus';
import { getAgentAvailability } from 'shared/selectors';
import { selectIsNewbuildingPushToCallEnabled } from 'shared/selectors/abExperiments';

export const NewbuildingOnlineStatusContainer = () => {
  const isEnabled = useSelector(selectIsNewbuildingPushToCallEnabled);
  const availability = useSelector(getAgentAvailability);

  if (!isEnabled || !availability?.available) {
    return null;
  }

  return <NewbuildingOnlineStatus />;
};
