import { setCookie } from '@cian/utils/lib/browser/cookie';

import { getCookie } from 'browser/utils/cookie';

const PUSH_TO_CALL_COOKIE_KEY = 'newbuildingPushToCallTooltipCounter';
const TIMES_TO_SHOW_PUSH_TO_CALL = [1, 3, 6];

export function pushToCallTooltipShowHandler() {
  if (typeof window === 'undefined') {
    return false;
  }

  const cookieValue = getPushToCallCookie();
  const count = cookieValue ? Number(cookieValue) : 0;
  const result = count + 1;

  createPushToCallCookie(result);

  return TIMES_TO_SHOW_PUSH_TO_CALL.includes(result);
}

function createPushToCallCookie(count: number) {
  setCookie(PUSH_TO_CALL_COOKIE_KEY, String(count), { expires: 365 * 86400, secure: false });
}

function getPushToCallCookie() {
  const times = getCookie(PUSH_TO_CALL_COOKIE_KEY);

  return times ? Number(times) : 0;
}
