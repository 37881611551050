import { Overline, UIHeading3, Avatar } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IDeveloperLogoProps {
  logoUrl: string | null;
  developerName: string;
  url: string;
}

export const DeveloperLogo: React.FC<React.PropsWithChildren<IDeveloperLogoProps>> = ({
  logoUrl,
  developerName,
  url,
}) => {
  return (
    <div className={style['logo']}>
      <a data-testid="developer-logo-link" href={url} target="_blank" rel="noreferrer">
        <Avatar type="builder" size={72} src={logoUrl} />

        <div className={style['title']}>
          <Overline>Застройщик</Overline>
          <UIHeading3 color="black_100">{developerName}</UIHeading3>
        </div>
      </a>
    </div>
  );
};
